<script>
import Base from "@backend/Base.vue";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";

export default {
  name: "BoContact",
  extends: Base,
  data() {
    return {
      Name: "BoContact",
      status: {},
      dataLength: 0,
      heroImage: {},
      contactHeroImage: "",
      previewContact: {}
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData();
  },
  methods: {
    viewContact(v) {
      this.previewContact = v;
      $("#modalContact").modal();
      
      const { ac_id, ac_is_active } = v;
      if (ac_is_active === 'N') {
        const { data } = this.data;
        const index = data.findIndex((c) => c.ac_id === ac_id);
        if (index !== -1) {
          BOGen.apirest("/" + this.Name + "/" + v.ac_id);
          data[index] = {
            ...data[index],
            ac_is_active: 'Y'
          }
        }
      }
    },
    editHeroImage(v) {
      $("#modalNewsImg").modal();
      this.contactHeroImage = v;
    },
    submitHeroImage(e) {
      BOGen.apirest(
        "/" + this.Name,
        {
          type: "updateHero",
          heroImage: {
            as_val: {
              img: this.heroImage.as_val.img,
              img_mobile: this.heroImage.as_val.img_mobile,
              alt_img: this.heroImage.as_val.alt_img,
              alt_img_mobile: this.heroImage.as_val.alt_img_mobile
            },
          },
        },
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".kr-img-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".kr-img-info");
            setTimeout(() => {
              $("#modalNewsImg").modal("hide");
            }, 500);
            this.refreshData();
          }
        },
        "POST"
      );
    },
  },
  watch: {
    "$route.query"() {
      if (this.$route.params.id) {
        this.$set(this.row, "page", this);
        setTimeout(() => {
          this.row.page = this.$route.query.page;
        }, 500);
      }
      this.refreshData();
    },
    "filter.level"() {
      this.search();
    },
    
    "filter.status"() {
      this.search();
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>

    <!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">
              Hero Image Kontak
            </h5>
          </div>
          <div class="card-body">
            <div class="kr-img-info"></div>
            <VForm @resp="submitHeroImage" method="post">
              <div class="row">
                <div class="struktur-info col-12"></div>
                <div v-if="moduleRole('Edit') && !$route.params.id" class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="hero"
                          uploadType="cropping"
                          v-model="(heroImage.as_val || {}).img"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image Mobile'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="heromobile"
                          uploadType="cropping"
                          v-model="(heroImage.as_val || {}).img_mobile"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img"
                        v-model="(heroImage.as_val || {}).alt_img"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image Yayasan Al Hidayah'
                        }"
                        :label="'Alt Image'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img_mobile"
                        v-model="(heroImage.as_val || {}).alt_img_mobile"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image Yayasan Al Hidayah'
                        }"
                        :label="'Alt Image Mobile'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                  </div>
                </div>
                <div v-else class="col-md-8">
                  <img
                    :src="uploader((heroImage.as_val || {}).img)"
                    :alt="(heroImage.as_val || {}).alt_img"
                  />
                </div>
                <div class="col-12 mt-3 text-right">
                  <button
                    v-if="moduleRole('Edit')"
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modalNewsImg" v-if="moduleRole('Edit')">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ubah Hero Image</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="ti-close"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="kr-img-info"></div>
            <VForm @resp="submitHeroImage" method="post">
              <BoField
                name="image"
                mandatory
                :label="'Hero Image'"
                class="mb-0"
              >
                <Uploader
                  name="image"
                  :param="{ thumbnail: true }"
                  type="hero"
                  :deleted="false"
                  uploadType="cropping"
                  v-model="contactHeroImage"
                ></Uploader>
              </BoField>

              <BoField
                mandatory
                name="ai_alt_img"
                v-model="(heroImage.as_val || {}).alt_img"
                :attr="{
                  type: 'text',
                  placeholder: 'e.g. Hero Image Kontak'
                }"
                :label="'Alt Image'"
                required=""
              ></BoField>

              <div class="col-12 mt-3 text-right">
                <button
                  v-if="moduleRole('Edit')"
                  type="submit"
                  class="btn btn-rounded btn-loading btn-info"
                >
                  Perbarui
                </button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <VForm @resp="search">
              <div class="row gutter-10">
                <div class="col-sm-5">
                  <h5 class="card-title">Daftar {{ ObjectName }}</h5>
                </div>
                <div v-if="dataLength > 0" class="col-sm-2">
                  <select2 :options="status" v-model="filter.status">
                    <option value="">-- Pilih Status --</option>
                  </select2>
                </div>
                <div v-if="dataLength > 0" class="col-sm-4">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input
                        type="text"
                        v-model="filter.search"
                        v-on:keyup.enter="search"
                        class="form-control"
                        placeholder="Cari..."
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-info"
                          type="button"
                          @click="search()"
                        >
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="dataLength > 0" class="col-3 col-sm-1">
                  <router-link
                    :to="{ name: Name }"
                    class="btn btn-block btn-warning"
                    >Reset</router-link
                  >
                </div>
              </div>
            </VForm>
          </div>
          <div class="table-responsive ajax-table">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    Nama Lengkap
                  </th>
                  <th>{{ fields.ac_email }}</th>
                  <th>{{ fields.ac_subject }}</th>
                  <th>Tanggal</th>
                  <th>Status</th>
                  <th class="text-center" width="120px">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v, k) in data.data" :key="k">
                  <td class="number">
                    {{ data.per_page * (data.current_page - 1) + k + 1 }}
                  </td>
                  <td>{{ v.ac_full_name || "-" }}</td>
                  <td>{{ v.ac_email || "-" }}</td>
                  <td>{{ v.ac_subject || "-" }}</td>
                  <td>{{ v.ac_create_at.dates('format2') || "-" }} WIB</td>
                  <td>
                    <label
                      v-if="v.ac_is_active == 'Y'"
                      class="label label-success"
                      >Read</label
                    >
                    <label
                      v-if="v.ac_is_active == 'N'"
                      class="label label-warning"
                      >Unread</label
                    >
                  </td>
                  <td class="btn-action text-center">
                    <a
                      class="icon_action"
                      href="javascript:;"
                      @click.prevent="viewContact(v)"
                      v-tooltip="'Lihat'"
                      ><i class="ti-eye"></i
                    ></a>
                  </td>
                </tr>
                <tr v-if="NotFound">
                  <td colspan="99">
                    <h5 class="tc">{{ NotFound }}</h5>
                  </td>
                </tr>
                <tr v-if="data.data === false">
                  <td colspan="99">
                    <LoadingSpinner light></LoadingSpinner>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination
            class="justify-content-end m-3"
            :data="data"
            :limit="3"
            @pagination-change-page="onPaging"
          ></Pagination>
        </div>
      </div>
    </div>

    <!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->

    <div class="modal fade" id="modalContact" v-if="moduleRole('Edit')">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail Kontak</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="ti-close"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="kr-img-info"></div>
            <div v-if="JSON.stringify(previewContact) !== '{}'">
              <VForm @resp="submitForm" method="post">
                <div class="info"></div>
                <div class="row">
                  <div class="col-sm-12">
                    <table class="table table-bordered">
                      <tr>
                        <td width="30%">Nama Lengkap</td>
                        <td>{{ previewContact.ac_full_name || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{{ previewContact.ac_email || '-' }}</td>
                      </tr>
                      <tr>
                        <td>No Telepon</td>
                        <td v-if="previewContact.ac_phone">0{{ previewContact.ac_phone }}</td>
                        <td v-else>-</td>
                      </tr>
                      <tr>
                        <td>Subjek</td>
                        <td>{{ previewContact.ac_subject || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Pesan</td>
                        <td>{{ previewContact.ac_message || '-' }}</td>
                      </tr>
                      <tr>
                        <td>Tanggal kirim</td>
                        <td>{{ previewContact.ac_create_at.dates("format2") || '-' }} WIB</td>
                      </tr>
                      <tr>
                        <td>Alamat IP</td>
                        <td>{{ previewContact.ac_ip || '-' }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </VForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
